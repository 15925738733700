export const lg_listroom1 = {
  listroom1_th: {
    title: "รายการห้องประชุมของคุณ",
    title_remark:"ผู้ที่มีสิทธิ์ปิดห้องประชุม คือ ผู้ที่สร้างห้องและผู้ดูแลระบบเท่านั้น",
    room_search: "ค้นหาชื่อห้อง",
    room_id: "รหัสห้อง : ",
    room_user: "ผู้สร้าง : ",
    room_company: "บริษัท : ",
    room_department: "แผนก : ",
    room_date: "วันที่สร้าง : ",
    room_schedule: "กำหนดประชุม : ",
    room_remark: "หมายเหตุ : ",
    room_message_pass: "ห้องประชุมนี้มีรหัสผ่าน",
    room_lobby: "Waiting Room : ",
    room_message_lobby: "เปิดใช้งาน Lobby",
    btn_invite: "เชิญสมาชิก",
    btn_join: "เข้าร่วมการประชุม",
    btn_close: "ปิดการประชุม",
    titleMessageAlert: "ปิดห้องประชุม",
    messageAlert: "ท่านไม่ใช่ผู้สร้างห้องประชุม ไม่สามารถปิดได้",
    dialog_invite_title: "ใส่อีเมลเพื่อส่งลิงก์ประชุม",
    dialog_invite_send_to: "ส่งอีเมล์ไปที่...",
    dialog_invite_copy_link: "คัดลอกลิงก์การประชุม",
    dialog_invite_copy_link_livestream:"คัดลอกลิงก์สตรีมสด (ในฐานะผู้ดู)",
    dialog_invite_send_link: "ส่งลิ้งค์",
    dialog_delete: "คุณแน่ใจหรือว่าต้องการลบ ห้องประชุุม นี้ ? คุณไม่สามารถกู้คืนได้หลังจาก คลิกยืนยัน",
    password:"รหัสผ่าน :",
    btn_join_livestream: "เข้าร่วมสตรีมสด",
    btn_send:"ส่ง",
    meet_option_verify_identify: "ยืนยันตัวตน",
    meet_sub__option_lobby: " เฉพาะผู้ใช้ที่ได้รับอนุญาตจากโฮสต์เท่านั้นจึงจะสามารถเข้าร่วมการประชุมได้",
    enter_sub_email:"ห้องประชุมนี้ต้องการ การยืนยัน OTP กรุณากรอกอีเมลของคุณเพื่อรับ OTP",
    require_sub_login:"ต้องยืนยันตัวตนเพื่อเข้าร่วมการประชุมนี้",
    require_sub_login2:"กรุณาเข้าสู่ระบบ Dmeets ก่อนเข้าร่วมการประชุม",
  },
  listroom1_en: {
    title: "My meetings",
    title_remark:"Only the person who created the meeting or the system administrator has the rights to close the meeting.",
    room_search: "Search for Room Name",
    room_id: "Room ID : ",
    room_user: "Created by : ",
    room_company: "Company : ",
    room_department: "Department : ",
    room_date: "Created date : ",
    room_schedule: "Schedule : ",
    room_remark: "Remark : ",
    room_message_pass: "This meeting room has a password",
    room_lobby: "Lobby : ",
    room_message_lobby: "Open Lobby",
    btn_invite: "Invite",
    btn_join: "Join Now as Meeting",
    btn_close: "Delete Meeting",
    titleMessageAlert: "Delete Meeting",
    messageAlert:"You are not the creator of the meeting room. You do not have the rights to close it.",
    dialog_invite_title: "Enter email address to send the meeting link",
    dialog_invite_send_to: "Send email to...",
    dialog_invite_copy_link: "Copy meeting link",
    dialog_invite_copy_link_livestream:"Copy livestream (as viewer) link",
    dialog_invite_send_link: "Send link",
    dialog_delete: "Are you sure you want to delete this room? You cannot undo this later.",
    password:"Password :",
    btn_join_livestream: "Join  Now as Livestream",
    btn_send:"Send",
    meet_option_verify_identify: "Verify user's identity",
    meet_sub__option_lobby: "Only users admitted by the host can join the meeting",
    enter_sub_email:"This meeting room requires OTP verification. Please enter your email to receive the OTP.",
    require_sub_login:"Authentication is required to join this meeting",
    require_sub_login2:"Please log into Dmeets before joining.",
  },
};
