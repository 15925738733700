<template>
  <v-main>
  <div>
    <!-- <div id='previewContainer' height="1080" width="1920"></div> -->
    
    <div v-if="checksessionmeeting && !showmobilelink">
      <!-- <v-container> -->
        <!-- <v-row align="center" justify="center"> -->
          <!-- <div id='previewContainer' height="80vh" width="80vw" style="width:60vw;height: 60vh;"></div> -->
          <!-- style="width:80vw;height: 60vh;"  -->
          <!-- style="background-color: #137866;" -->
         <div id='sessionContainer' >
        </div>
        <dyte-meeting id="my-meeting" ref="dytemeeting" >
        </dyte-meeting>
      <!-- </v-row> -->
      
      <!-- </v-container> -->
    </div>
    <!-- <video id="my-screen-share-content-video" height="1080" width="1920"></video>
<canvas id="my-screen-share-content-canvas" height="1080" width="1920"></canvas>
    <canvas id="participant-videos-canvas" width="1920" height="1080"></canvas>
    <video id="my-self-view-video" width="1920" height="1080"></video>
<canvas id="my-self-view-canvas" width="1920" height="1080"></canvas> -->
<div class="set-profile"  v-if="!checksessionmeeting && !showmobilelink">
  <v-container>
    <!-- <div style="color:green" > Zoom Meeting</div> -->
    
    <v-card
    v-if="checkauthlogin"
        class="mx-auto my-12"
        col="12"
            lg="12"
            md="12"
            sm="12"
        max-width="500"
      >
      
      <v-card-title>{{ lg_meet_page.require_login }}</v-card-title>
      <v-card-subtitle>{{ lg_meet_page.require_sub_login }}</v-card-subtitle>
      <v-card-subtitle class="mt-n6">{{ lg_meet_page.require_sub_login2 }}</v-card-subtitle>
      <v-card-text>
        <v-btn
      color="blue"
      class="white--text" block @click="goToLogin()"
            >{{ lg_meet_page.login }} </v-btn
          >
        </v-card-text>
    </v-card>

    <v-card
    v-if="checksendotp"
        class="mx-auto my-12"
        col="12"
            lg="12"
            md="12"
            sm="12"
        max-width="500"
      >
      <v-card-title>{{ lg_meet_page.enter_email }}</v-card-title>
      <v-card-subtitle>{{ lg_meet_page.enter_sub_email }}</v-card-subtitle>
      <v-card-text>
          <form @submit.prevent="checkAndSendOTP()">
            <v-text-field
              v-model="email"
              :label="lg_meet_page.enter_email"
              outlined
            ></v-text-field>
            <v-btn :disabled="disablebtnsendmail" color="primary" min-height="40" type="submit" block>{{
              lg_meet_page.btn_enter
            }}</v-btn>
          </form>
        </v-card-text>
    </v-card>



    <v-dialog v-model="otp_box" max-width="500px" persistent>
      <v-card class="justify-center align-center">
        <v-card-title class="text-h5 justify-center align-center"
          >{{ lg_meet_page.enter_otp }}</v-card-title
        >
       <v-row class="ma-0 justify-center align-center">
        {{ lg_meet_page.enter_sub_otp }}  
      </v-row>  
      <!-- <v-row class="ma-0 justify-center align-center">
       {{ dataotp.email }}
      </v-row>  -->

      <v-row class="ma-0 justify-end align-end mt-2">
      <v-btn
      :disabled="!isCoolingDown"
      color="blue"
      class="ma-2 white--text"
      @click="checkAndSendOTP()"
    >
    <v-icon
        left
        dark
      >
      mdi-reload
      </v-icon>
    {{ lg_meet_page.request_a_new_otp }} {{ timecooldown }}
    </v-btn>
  </v-row>

      <v-row class="ma-0 justify-center align-center mt-2">
        <v-col cols="12" xs="2" sm="2" md="2">
          <v-text-field v-model="sendotpdata.otp1"  ref="otp1" class="justify-center" autofocus @focus='$nextTick(() => {
       $refs.otp1.focus()
      });' 
       @keyup='$nextTick(() => {
        if(sendotpdata.otp1 == ""){
        }else{
          if(Number(sendotpdata.otp1) || sendotpdata.otp1=="0"){
            $refs.otp2.focus()
          }else{
            sendotpdata.otp1="";
          }
        }
      });'
      outlined dense hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="12" xs="2" sm="2" md="2">
          <v-text-field v-model="sendotpdata.otp2" ref="otp2" class="justify-center"  
          @keyup='$nextTick(() => {
        if(sendotpdata.otp2 == ""){
        }else{
          if(Number(sendotpdata.otp2) || sendotpdata.otp2=="0"){
            $refs.otp3.focus()
          }else{
            sendotpdata.otp2="";
          }
        }
      });'
       outlined dense hide-details>
          </v-text-field>
        </v-col> <v-col cols="12" xs="2" sm="2" md="2">
          <v-text-field v-model="sendotpdata.otp3" ref="otp3" class="justify-center "  
          @keyup='$nextTick(() => {
        if(sendotpdata.otp3 == ""){
        }else{
          if(Number(sendotpdata.otp3) || sendotpdata.otp3=="0"){
            $refs.otp4.focus()
          }else{
            sendotpdata.otp3="";
          }
        }
      });'
       outlined dense hide-details>
          </v-text-field>
        </v-col> <v-col cols="12" xs="2" sm="2" md="2">
          <v-text-field v-model="sendotpdata.otp4" ref="otp4" class="justify-center " 
          @keyup='$nextTick(() => {
        if(sendotpdata.otp4 == ""){
        }else{
          if(Number(sendotpdata.otp4) || sendotpdata.otp4=="0"){
            $refs.otp5.focus()
          }else{
            sendotpdata.otp4="";
          }
        }
      });'
       outlined dense hide-details>
          </v-text-field>
        </v-col> <v-col cols="12" xs="2" sm="2" md="2">
          <v-text-field v-model="sendotpdata.otp5" ref="otp5" class="justify-center "
          @keyup='$nextTick(() => {
        if(sendotpdata.otp5 == ""){
        }else{
          if(Number(sendotpdata.otp5) || sendotpdata.otp5=="0"){
            $refs.otp6.focus()
          }else{
            sendotpdata.otp5="";
          }
        }
      });'
       outlined dense hide-details>
          </v-text-field>
        </v-col> <v-col cols="12" xs="2" sm="2" md="2">
          <v-text-field v-model="sendotpdata.otp6" ref="otp6" class="justify-center " @keyup='$nextTick(() => {
       
          if(Number(sendotpdata.otp6) || sendotpdata.otp6=="0"){
          }else{
            sendotpdata.otp6="";
          }
      });'
       outlined dense hide-details>
          </v-text-field>
        </v-col>
        <span v-if="otpstatusisfail"  class="red--text">{{ lg_meet_page.theotpyou_entered }} 
          <v-btn
      color="blue"
      class="ma-2 white--text"
      @click="checkAndSendOTP()"
    >
    <v-icon
        left
        dark
      >
      mdi-reload
      </v-icon>
    {{ lg_meet_page.resubmit }}   
    
    </v-btn>

        </span>
      </v-row> 
   
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="otpstatusisfail" color="blue darken-1" text @click="verifyOTP()"
            >Confirm OTP </v-btn
          >
          <v-btn color="red darken-1" text @click="goToListRoom()"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-card v-if="otp_box"  
    class="mx-auto my-12"
        col="12"
            lg="12"
            md="12"
            sm="12"
        max-width="500" >
      <v-card-title primary-title> {{ lg_meet_page.title2 }} </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="verifyOTP()">
          <v-text-field :label="lg_meet_page.txt_otp" v-model="otp" outlined />
          <v-btn color="primary" min-height="40" type="submit" block>{{
              lg_meet_page.btn_enter
            }}</v-btn>
        </v-form>
      </v-card-text>
    </v-card> -->

    <v-card
    v-if="checkmeetpass"
        class="mx-auto my-12"
        col="12"
            lg="12"
            md="12"
            sm="12"
        max-width="500"
      >
      <v-card-title>{{ lg_meet_page.input_password }}</v-card-title>
      <v-card-text>
          <form @submit.prevent="checkPassword()">
            <v-text-field
              v-model="passWord"
              :label="lg_meet_page.label_password"
              outlined
              type="password"
              autocomplete="new-password"
            ></v-text-field>
            <v-btn color="primary" min-height="40" type="submit" block>{{
              lg_meet_page.btn_enter
            }}</v-btn>
          </form>
        </v-card-text>
    </v-card>

    <v-card
    v-if="checksetname"
        class="mx-auto my-12"
        col="12"
            lg="12"
            md="12"
            sm="12"
        max-width="500"
      >
      <v-card-title>{{ lg_meet_page.input_name }}</v-card-title>
      <v-card-text>
          <form @submit.prevent="checkname()">
            <v-text-field
              v-model="userName"
              :label="lg_meet_page.input_name"
              outlined
              type="text"
            ></v-text-field>
            <v-btn color="primary" min-height="40" type="submit" block>{{
              lg_meet_page.join_meeting
            }}</v-btn>
          </form>
        </v-card-text>
    </v-card>
  
   
          <!-- <video id="my-self-view-video" width="1920" height="1080"></video>
<canvas id="my-self-view-canvas" width="1920" height="1080"></canvas> -->


    <!-- <v-row class="pt-10 ma-1">
      <v-col align="center" justify="center"  cols="12" xl="6" lg="6" md="12" sm="12" xs="12">
      </v-col>
      <v-col align="center" justify="center" cols="12" xl="6" lg="6" md="12" sm="12" xs="12">
       <v-row align="center" justify="center">
          
        
     </v-row>
      </v-col>
    </v-row> -->
    <v-dialog v-model="alertDlg" max-width="500">
      <v-card>
        <v-card-title class="headline">{{
          this.titleMessageAlert
        }}</v-card-title>

        <v-card-text class="body">
          {{ this.messageAlert }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            @click="alertDlg = false"
            col="12"
            lg="12"
            md="12"
            sm="12"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</div>
<div
v-if="showmobilelink"
      class="containermobileall">
<div
v-if="showmobilelink"
      class="containermobile"
      style=""
    >
    <span v-bind:style="{ color: '#000000',fontWeight:'400 !important',align:'left',textDecoration: 'underline',cursor: 'pointer',fontSize:'12px' }" @click="onClickMenu('/')">www.dmeets.co.th</span>
    </div>
    <div
v-if="showmobilelink"
      class="containermobilebottom"
      style=""
    >
    <v-row align="center" justify="center" class="mb-4">
        <v-btn
        style="border-radius: 100px"
        min-height="40"
         color="white"
            @click="checkuserAgent"
            col="12"
            lg="12"
            md="12"
            sm="12"
            size="large"
            rounded
            >
              <span>Download Now</span>
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
        </v-row>

        <!-- <v-row align="center" justify="center" class="mb-4">
          <span v-bind:style="{ color: '#ffffff',fontWeight:'400 !important',align:'left',textDecoration: 'underline',cursor: 'pointer' }"  @click="showmobilelink = false">Skip for now</span>
        </v-row> -->
        
  </div>
  </div>

</div>

<SuccessDialog
        :status="dialogAdd"
        :text_color="text_color"
        :title="title"
        :message="message"
      />
      <SuccessDialogPush
        :status="dialogAdd"
        :text_color="text_color"
        :title="title"
        :message="message"
        :link="link"
      />
</v-main>
</template>

<script>
import { getByMeetNameOnlyzoom } from "@/services/api_meetroom";
import api from "@/services/api";
import {lg_joinmeetbtid} from './language/lg_header_joinmeetbyid.js'
import { feature } from "../services/constants.js";
// import { ZoomMtg } from '@zoomus/websdk';
import { lg_meet_page } from "./language/lg_meet_page";
import { server } from "../services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import DyteClient from "@dytesdk/web-core";
import DytePlugin from "@dytesdk/plugin-sdk";
import { lg_header_feature } from "./language/lg_header_feature.js";
import NoSleep from '@uriopass/nosleep.js';


export default {
  components: {
    SuccessDialog,
    SuccessDialogPush
  },
  data: () => ({
    timecooldown: 180,
    isCoolingDown:false,
    disablebtnsendmail:false,
    sendotpdata:{
        otp1:"",
        otp2:"",
        otp3:"",
        otp4:"",
        otp5:"",
        otp6:""
      },
    meet_id:"",
    dialogAdd: false,
    text_color: "text-h5 green--text text-center",
    title: "green",
    message: "green",
    link: "",
    typeroom:"",
    durationtimeend:0,
    showmobilelink:false,
    checkentername:false,
    checksetname:false,
    checkmeetpass:false,
    checksendotp:false,
    checkauthlogin:false,
    otp_box:false,
    otp:null,
    checksessionmeeting:false,
    titleMessageAlert:"",
    messageAlert:"",
    alertDlg:false,
    otpstatusisfail:false,
    dataroom:{},
      authEndpoint : '',
sdkKey : '7d46FNyTSWWCBHrmtrkxzw',
sdkSecret : 'VunUMMyEpCbjdMGh8pGNJ7AGKKjbbwPo',
meetingNumber : '',
passWord : 'dcmin',
role : 1,
userName : '',
userEmail : '',
email:"",
verify: {
        meet_id: "",
        ver_email: "",
        ver_otp: "",
        ver_expire: "",
      },
registrantToken : '',
zakToken : '', 
leaveUrl : `${window.location.origin}/listroom`,
signature:'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOm51bGwsImlzcyI6ImdWRktRRXpZUlVpVGl2bElhTi1teUEiLCJleHAiOjE3MDMwNDEyMjksImlhdCI6MTcwMjk1NDgzMX0.UNv7rWP7PeL_UZ8nEJyrXFSl8TToLA2hrwq0DTdlgr8',

  }),
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
      async JoinMeetingWebSDK(payload){   
        // console.log(payload);    
        // console.log(this.dataroom)
        // return;
        
const resulttoken = await api.generateSignatureMeet({
    "meet_appid":this.dataroom.meet_appid,
    "name":this.userName,
    "preset_name":localStorage.getItem(server.ROLE) == "Admin" ? "group_call_host":"group_call_participant_free",
    "custom_participant_id": localStorage.getItem(server.USER_ID)?localStorage.getItem(server.USER_ID):"0",
    "meet_name":this.dataroom.meet_name,
    "account_id":localStorage.getItem(server.USER_ID),
    "typeroom":this.typeroom
});
// return;
// console.log(resulttoken)
if(resulttoken.status == 200 && resulttoken.data.data.token){
  this.checksessionmeeting = true;
const meeting = await DyteClient.init({
        authToken: resulttoken.data.data.token,
        // roomName: 'Mary Sue',
        defaults: {
          audio: false,
          video: false,
        },
      });

      var noSleep = new NoSleep();

      noSleep.enable();
      // const plugin = DytePlugin.init();
      // await plugin.enableForAll();
      // await plugin.stores.populate('storeName');


// const plugins = meeting.plugins;
// console.log(plugins)
      await meeting.join();
// console.log(meeting.self)
// console.log(meeting.poll.vote(poll, selectedPollOption))
      document.getElementById('my-meeting').showSetupScreen = false;
      document.getElementById('my-meeting').meeting = meeting;
      // this.$refs.dytemeeting.meeting = meeting;
      // console.log(this.$refs.dytemeeting.config)
      // console.log(meeting.meta.sessionId)
      meeting.self.on('roomLeft', ({ state }) => {
  if (state === 'left') {
    this.$router.push("/listroom");
  }else if(state ==='ended'){
    this.$store.state.global_dialog_push = true;
        this.setupAlertDialogPush(
          true,
          "Meeting Ended",
          "The host has ended the meeting.\n Thank you for joining!",
          "text-h5 red--text text-center",
          "/listroom"
        );
    // this.$router.push("/listroom");
  }
});

meeting.polls.on('pollsUpdate',async ({ polls, newPoll })  => {
  // console.log(polls[0], newPoll);
  // console.log(meeting.self.userId)
  // console.log(polls[0].createdByUserId)
  polls.forEach(async(x,i)=>{
    if(newPoll && meeting.self.userId == x.createdByUserId){
   await api.addPollVote({
      meet_topic:this.dataroom.meet_topic,
      session_id:meeting.meta.sessionId,
      meet_room_id:this.dataroom.id,
      polls_id:x.id,
      createdby_userid:x.createdByUserId,
      created_by:x.createdBy,
      anonymous:x.anonymous,
      hide_votes:x.hideVotes,
      question:x.question,
      options:JSON.stringify(x.options),
      voted:JSON.stringify(x.voted),
      is_newpoll:newPoll,
      user_create:localStorage.getItem(server.USER_ID)?localStorage.getItem(server.USER_ID):0
    });
  }else{
    await api.updatePollVote(x.id,{polls_id:x.id,
      createdby_userid:x.createdByUserId,
      created_by:x.createdBy,
      anonymous:x.anonymous,
      hide_votes:x.hideVotes,
      question:x.question,
      options:JSON.stringify(x.options),
      voted:JSON.stringify(x.voted),
      user_create:localStorage.getItem(server.USER_ID)?localStorage.getItem(server.USER_ID):0
    });
  }
  })
 
});

// function addMinutes(date, minutes) {
//   date.setMinutes(date.getMinutes() + minutes);
//   return date;
// }
// const startduration = meeting.meta.meetingStartedTimestamp;
// const date = new Date(meeting.meta.meetingStartedTimestamp);
// const newDate = addMinutes(date, 1);
// //this.durationtimeend = newDate;
// console.log(meeting.meta.meetingStartedTimestamp)
// console.log(newDate);



// var h = new Date(meeting.meta.meetingStartedTimestamp).getHours();
// var m = new Date(meeting.meta.meetingStartedTimestamp).getMinutes();
// console.log(h)
// console.log(m)
// console.log(meeting.self);

// this.durationtimeend = setTimeout(()=>{
//   console.log("=====================")
//   console.log(new Date(Date.now()))
//   console.log(newDate)
//   console.log("=====================")
// if(Date.now() >= newDate){
//   console.log("leave room!!!!!");
//   clearTimeout(this.durationtimeend);
//   meeting.leave();
// }   
//     }, 10000);

}
else if(resulttoken.status == 206){
  this.$swal.fire(this.lg_meet_page.title_error, this.lg_header_feature.titlefreeplanoptions1, "error");
}
return;
},


async checkPassword(){
  if(this.passWord == this.dataroom.meet_pass){
    this.checkmeetpass = false;
    this.checksetname = true;
    // this.JoinMeeting(this);
  }else{
    this.titleMessageAlert = this.lg_meet_page.dialog_check_data;
    this.messageAlert = this.lg_meet_page.invalid_password;
    this.alertDlg = true;
  }
  

},
async checkname(){
  if(this.userName){
      // this.checksessionmeeting = true;
   await this.JoinMeetingWebSDK(this);
   
  }else{
    this.titleMessageAlert = this.lg_meet_page.dialog_check_data;
    this.messageAlert = this.lg_meet_page.input_name;
    this.alertDlg = true;
  }
  

},
async checkuserAgent(){
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)){
    location.href = "https://apps.apple.com/th/app/dmeets/id6479974253";
      }else{
    location.href = "https://play.google.com/store/apps/details?id=com.dcm.dmeetszoom.dcm_dmeetszoom";
      }
},

async checkAndSendOTP() {
      this.disablebtnsendmail = true;
      this.$store.state.loadMenu = true;
      this.isCoolingDown = false;

      // this.resultJson = await api.getAccountByEmail(this.email);
      // console.log(JSON.stringify(this.resultJson.data));

      // if (this.resultJson.data != null) {
      //   this.$store.state.verify_status = true;
      //   this.$router.push(`/meet/${this.meet_appid}/${this.meet_id}`);
      // } else {
      this.verify.meet_id = this.meet_id;
      this.verify.ver_email = this.email;
      this.verify.ver_otp = Math.floor(
        100000 + Math.random() * 900000
      ).toString();

      var d1 = new Date(),
        d2 = new Date(d1);
      d2.setMinutes(d1.getMinutes() + 5);
      // alert(d2.toString());
      this.verify.ver_expire = d2.toString();

      // console.log(this.verify);
      try {
        await api.deleteVerify(this.verify.meet_id, this.verify.ver_email);
      } catch (error) {
        console.log(error);
      }

      const res = await api.addVerify(this.verify);
      // console.log("res : " + res.status);
      if (res.status == 201) {
        //   this.titleMessageAlert = "Send OTP Success";
        //   this.messageAlert = "กรุณาตรวจ OTP ที่อีเมล์";
        //   this.alertDlg = true;
        const resotp = await api.sendOTP(this.verify);
        if (resotp.status == 200) {
          this.sendotpdata = {
        otp1:"",
        otp2:"",
        otp3:"",
        otp4:"",
        otp5:"",
        otp6:""
      };
      this.disablebtnsendmail = false;
          // this.titleMessageAlert = "Send OTP Success";
          // this.messageAlert = "กรุณาตรวจ OTP ที่อีเมล์";
          this.titleMessageAlert = this.lg_meet_page.dialog_popup_success;
          this.messageAlert = this.lg_meet_page.dialog_popup_success_message;
          // this.alertDlg = true;
          this.checksendotp = false,
          this.otpstatusisfail = false;

    let timeLeft = this.timecooldown;
const timer = setInterval(() => {
  timeLeft--;
  this.timecooldown = timeLeft;

  if (timeLeft <= 0) {
    clearInterval(timer);
    this.isCoolingDown = true;
    this.timecooldown = 180;
  }
}, 1000);
          
          this.otp_box = true;
        } else {
          // this.titleMessageAlert = "Send OTP Fail";
          // this.messageAlert = "กรุณาส่ง OTP ใหม่อีกครั้ง";
          this.disablebtnsendmail = false;
          this.titleMessageAlert = this.lg_meet_page.dialog_popup_fail;
          this.messageAlert = this.lg_meet_page.dialog_popup_fail_message;
          this.alertDlg = true;
        }
      } else {
        // this.titleMessageAlert = "Send OTP Fail";
        // this.messageAlert = "กรุณาส่ง OTP ใหม่อีกครั้ง";
        this.disablebtnsendmail = false;
        this.titleMessageAlert = this.lg_meet_page.dialog_popup_fail;
        this.messageAlert = this.lg_meet_page.dialog_popup_fail_message;
        this.alertDlg = true;
      }
      //}

      this.$store.state.loadMenu = false;
    },
    async verifyOTP() {
      let allotpnumber = this.sendotpdata.otp1+this.sendotpdata.otp2+this.sendotpdata.otp3+this.sendotpdata.otp4+this.sendotpdata.otp5+this.sendotpdata.otp6;
      this.otp = allotpnumber;
      const resOtp = await api.checkOTP(this.meet_id, this.email, this.otp);
        console.log(resOtp);
      if (resOtp.data != null) {
        let dn = new Date();
        let dt = new Date(resOtp.data.ver_expire);
        dt.setHours(dt.getHours() + 7);
        dn.setHours(dn.getHours() + 7);
        // console.log(
        //   "dexp str: " + dt.toLocaleString("th-TH", { timeZone: "UTC" })
        // );

        dt = dt.toLocaleString("th-TH", { timeZone: "UTC" });
        dn = dn.toLocaleString("th-TH", { timeZone: "UTC" });

        // console.log("dexp dt: " + dt);
        // console.log("dexp dn: " + dn);

        if (dn < dt) {
          //   alert("Joint Meeting Success");
          this.$store.state.verify_status = true;
          // this.$router.push(`/meet/${this.meet_appid}/${this.meet_id}`);
          this.otp_box = false;
          if(this.dataroom.meet_pass === null || this.dataroom.meet_pass === ''){
            this.userName = localStorage.getItem(server.FULLNAME);
            this.passWord = 'dcmin';
            this.checkmeetpass = false;
            this.checksetname = true;
            // this.JoinMeeting(this);
          }else{
            this.userName = localStorage.getItem(server.FULLNAME);
            this.passWord = '';
            this.checksetname = false;
            this.checkmeetpass = true;
          }
        } else {
          // this.titleMessageAlert = "OTP Expired";
          // this.messageAlert = "OTP หมดอายุ กรุณาส่ง OTP ใหม่อีกครั้ง";
          this.titleMessageAlert = this.lg_meet_page.dialog_popup_expire;
          this.messageAlert = this.lg_meet_page.dialog_popup_expire_message;
          // this.alertDlg = true;
          this.otpstatusisfail = true;
        }
      }else{
        this.titleMessageAlert = this.lg_meet_page.confirmotp_fail_title;
          this.messageAlert = this.lg_meet_page.confirmotp_fail;
          // this.alertDlg = true;
          this.otpstatusisfail = true;
      }
    },

onClickMenu(link) {
      this.$router.push(link).catch((err) => {});
    },

    goToListRoom() {
      this.$router.push('/listroom').catch((err) => {});
    },
    goToLogin(){ 
      this.$router.push('/login-account').catch((err) => {});
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
   computed: {},
   async beforeCreate() {
    window.scroll({ top: 0,left: 0})
      this.$store.state.page_reload = false;

      localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_meet_page = lg_meet_page.meet_page_th)
      : (this.lg_meet_page = lg_meet_page.meet_page_en);

      localStorage.getItem(feature.LANGUAGE) === "th"
        ? this.lg_header_feature = lg_header_feature.lg_header_feature_th
        : this.lg_header_feature = lg_header_feature.lg_header_feature_en;

    },
    async mounted() {
  let url = window.location.href
      // let result = url.includes("vpaas-magic-cookie-e205800afeca4002aad705d1994953cb");
       let host = (url.split('/')[0] +"//" +url.split('/')[2]);
      let meetId = url.split('/')[5].trim();
      this.meet_id = url.split('/')[5].trim();
      this.typeroom = url.split('/')[6].trim();
     try{
      if(meetId){
          const res = await api.getByMeetNameOnlydyte(meetId);
          // console.log(res);
          
          this.dataroom = res.data;
          if(this.dataroom.meet_verify_login === 'Y' && !localStorage.getItem(server.USER_ID)){
            this.checkauthlogin = true;
          }else if(this.dataroom.meet_verify_sta === 'Y'){
            this.checksendotp = true;

          }else if(this.dataroom.meet_pass === null || this.dataroom.meet_pass === ''){
            this.userName = localStorage.getItem(server.FULLNAME);
            this.passWord = 'dcmin';
            this.checkmeetpass = false;
            this.checksetname = true;
            // this.JoinMeeting(this);
          }else{
            this.userName = localStorage.getItem(server.FULLNAME);
            this.passWord = '';
            this.checksetname = false;
            this.checkmeetpass = true;
          }
         
        const {
          data: { meet_appid, meet_sta },
        } = res;
        if (meet_sta !== "Y") {
           this.$store.state.checklinkmeeting = true;
            
this.$router.push("/main-page");
       
        }
        
      }else{
    this.$router.push("/main-page");
       
      }
     }catch(e){
       this.$store.state.checkidmeeting = true;
       this.$router.push("/main-page");
     }

  this.$store.state.meetSta = true;
  this.$store.state.navMenu = false;
  this.$store.state.loadFooter = false;
  // await this.JoinMeetingWebSDK(this);
},
async created() {
  let url = window.location.href
      // let result = url.includes("vpaas-magic-cookie-e205800afeca4002aad705d1994953cb");
       let host = (url.split('/')[0] +"//" +url.split('/')[2]);
      let meetId = url.split('/')[5].trim();
      this.meet_id = url.split('/')[5].trim();
      this.typeroom = url.split('/')[6].trim();
        if (this.isMobile() == true) {
          this.showmobilelink = true;
          // setTimeout(() => {
            window.location.replace(
          `dmeets://dmeets.dcm.co.th/?meet_appid=${meetId}`
        );
        //     window.location.replace(
        //   "https://play.google.com/store/apps/details?id=com.dcm.dmeetszoom.dcm_dmeetszoom"
        // );
        // window.location.replace(
        //   "https://apps.apple.com/th/app/dmeets/id6479974253"
        // );
      // }, 1000);
        // window.location.href = `http://localhost:9291/weblink/deeplink/${this.$route.params.appId}/${this.$route.params.roomName}`;
        return;
      }
      this.showmobilelink = false;
  // console.log(this.$route.params.roomName);
  return;
  this.JoinMeeting(this);
},

};

</script>

<style scoped>
.textinform{
  width:75%;
}

@media (min-width: 1260px) {
  .joinmeeting-container {
    margin-top: 15rem
  }
}
/* #my-self-view-video, #my-self-view-canvas {
   width: 100%;
   height: auto;
} */
html,
body,
#jaas-container {
  height: 100vh;
  background: #000;
}

.set-profile {
  height: 100vh;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* .set-meeting{
  height: 100vh;
  width: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */
.app-logo {
  width: 150px;
}
/* #my-self-view-video, #my-self-view-canvas {
   width: 100%;
   height: auto;
} */
/* #sessionContainer {
  margin: 2rem;
} */

#participant-videos-canvas {
   width: 100%;
   height: auto;
}
#my-screen-share-content-video, #my-screen-share-content-canvas {
  width: 100%;
  height: auto;
  display: none;
}

img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .button {
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      display: block;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
      border-radius: 5px;
    }
    .button-blue {
      background-color: #008cba;
    } 
    .h1 {
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    .h2 {
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    .containermobileall{
      height:100vh;
      width: 100vw;
    }
    .containermobile{
      background-image: url('../assets/meetingmobile.png');
      height: 80vh;
      width: 100vw;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .containermobilebottom{
      height: 20vh;
      width: 100vw;
    }

    .v-main {
      background-image: linear-gradient(to right, #222471, #22257b, #212786, #202890, #1f299b);
      height: 100vh;
      width: 100vw;
}
</style>
