export const lg_meet_page = {
  meet_page_th: {
    input_password: "ระบุ รหัสผ่าน",
    label_password: "รหัสผ่าน",
    btn_save: "บันทึก",
    invalid_url: "URL ไม่ถูกต้อง",
    invalid_password: "รหัสผ่าน ไม่ถูกต้อง",
    dialog_check_data: "กรุณาตรวจสอบข้อมูล",
    input_name: "ระบุชื่อ",
    join_meeting: "เข้าร่วมประชุม",
    btn_enter: "ตกลง",
    title_error: "ผิดพลาด",
    btn_confirm1: "ส่งอีเมล OTP",
    email1: "อีเมล",
    title2: "ยืนยัน OTP",
    txt_otp: "OTP",
    dialog_popup_success: "ส่ง OTP สำเร็จ",
    dialog_popup_success_message: "กรุณาตรวจ OTP ที่อีเมล์",
    dialog_popup_fail: "ส่ง OTP ล้มเหลว",
    dialog_popup_fail_message: "กรุณาส่ง OTP ใหม่อีกครั้ง",
    dialog_popup_expire: "OTP หมดอายุ",
    dialog_popup_expire_message: "OTP หมดอายุ กรุณาส่ง OTP ใหม่อีกครั้ง",
    confirmotp_fail_title:"รหัส OTP ไม่ถูกต้อง",
    confirmotp_fail:"รหัส OTP ไม่ถูกต้อง โปรดลองอีกครั้ง",
    enter_email:"กรอกอีเมล",
    enter_sub_email:"ห้องประชุมนี้ต้องการ การยืนยัน OTP กรุณากรอกอีเมลของคุณเพื่อรับ OTP",
    enter_otp:"กรอก OTP",
    enter_sub_otp:"กรอก OTP ที่ส่งไปยังอีเมลของคุณเพื่อยืนยันและเข้าร่วมการประชุม",
    theotpyou_entered:"OTP ที่คุณป้อนไม่ถูกต้อง กรุณาขอรหัส OTP ใหม่อีกครั้ง",
    resubmit:"ส่งใหม่อีกครั้ง",
    require_login:"ต้องเข้าสู่ระบบ",
    require_sub_login:"ต้องยืนยันตัวตนเพื่อเข้าร่วมการประชุมนี้",
    require_sub_login2:"กรุณาเข้าสู่ระบบ Dmeets ก่อนเข้าร่วมการประชุม",
    login:"เข้าสู่ระบบ",
    request_a_new_otp:"ขอรหัส OTP ใหม่",

  },
  meet_page_en: {
    input_password: "Enter password",
    label_password: "Password",
    btn_save: "Save",
    invalid_url: "Invalid URL",
    invalid_password: "Invalid password",
    dialog_check_data: "Please check your information",
    input_name: "Enter Name",
    join_meeting: "Join Meeting",
    btn_enter: "Enter",
    title_error: "Error",
    btn_confirm1: "Send Email OTP",
    email1: "Email",
    title2: "Verify OTP",
    txt_otp: "OTP",
    dialog_popup_success: "Sent OTP successful",
    dialog_popup_success_message: "Please check OTP in your email",
    dialog_popup_fail: "Send OTP failed",
    dialog_popup_fail_message: "Please send OTP again",
    dialog_popup_expire: "OTP Expired",
    dialog_popup_expire_message: "OTP has expired, please send the OTP again",
    confirmotp_fail_title:"Invalid OTP",
    confirmotp_fail:"Invalid OTP Please try again",
    enter_email:"Enter Email",
    enter_sub_email:"This meeting room requires OTP verification. Please enter your email to receive the OTP.",
    enter_otp:"Enter OTP",
    enter_sub_otp:"Enter the OTP sent to your email to verify and join the meeting.",
    theotpyou_entered:"The OTP you entered is incorrect. Please resend a new OTP.",
    resubmit:"Resubmit",
    require_login:"Require Login",
    require_sub_login:"Authentication is required to join this meeting",
    require_sub_login2:"Please log into Dmeets before joining.",
    login:"Login",
    request_a_new_otp:"Request new OTP",

  },
};

