<template lang="html">
  <v-container fluid class="login-container">
    <v-row>
      <v-card :style="widthcard">
        <v-card-title class="justify-center">{{
          this.lg_login.title
        }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form @submit.prevent="submit" v-model="valid" ref="form">
            <!-- Username field -->
            <v-text-field
              dense
              :label="this.lg_login.email"
              :placeholder="this.lg_login.email_place"
              v-model="account.username"
              required
              outlined
            />

            <!-- Password field -->
            <v-text-field
              :label="this.lg_login.password"
              :placeholder="this.lg_login.password_place"
              v-model="account.password"
              :append-icon="e1 ? 'visibility' : 'visibility_off'"
              @click:append="e1 = !e1"
              :type="e1 ? 'password' : 'text'"
              required
              outlined
              dense
            />
            <p
              class="linkunderline"
              @click.prevent="$router.push('/forgot-password')"
            >
              {{ this.lg_login.btn_forgot }}
            </p>

            <v-alert
              v-model="$store.getters['altlogin']"
              dense
              outlined
              type="error"
            >
              {{ messageAlert }}
            </v-alert>

            <v-divider></v-divider>
            <!-- Buttons   justify-space-between -->
            <v-layout>
              <v-btn
                :small="buttonsmall"
                color="#205295"
                outlined
                text
                @click.prevent="$router.push('/register-account')"
                >{{ this.lg_login.btn_register }}</v-btn
              >

              <div>
                <v-btn
                  :small="buttonsmall"
                  class="ml-5"
                  v-bind:style="{ marginLeft: '2rem', backgroundColor: '#205295', color: '#ffffff' }"
                  type="submit"
                  :class="{
                    'white--text': valid,
                    disabled: !valid,
                  }"
                  >{{ this.lg_login.btn_login }}</v-btn
                >
              </div>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>

    <LoadingDialog></LoadingDialog>
  </v-container>
</template>

<script lang="js">

import api from "@/services/api";
import LoadingDialog from "@/components/cards/LoadingDialog";
import { server } from "../services/constants";
import { feature } from "../services/constants";
import { lg_login } from "./language/lg_login.js";

  export default  {
    name: 'login',
    props: [],
    created() {
    },
    components: {
      LoadingDialog,
    },
   async beforeCreate() {
      this.$store.state.page_reload = false;
      this.$store.state.altLogin = false;

      // alert(localStorage.getItem("language"))
      // console.log("language login : " + localStorage.getItem(feature.LANGUAGE));

      localStorage.getItem(feature.LANGUAGE) === "th"
        ? this.lg_login = lg_login.login_th
        : this.lg_login = lg_login.login_en;


        // console.log("lg_login: " + JSON.stringify(this.lg_login));
    },
    mounted () {
      localStorage.setItem(server.MEET_STA, "false");
      if (api.isLoggedIn()){
        this.$router.push("/main-page")
      }
      this.$store.state.meetSta = false;
  this.$store.state.navMenu = false;
  this.$store.state.loadFooter = true;
    },
    data () {
          return {
            messageAlert: this.lg_login.messageAlert,
            account:{
              username:"",
              password:""
            },
            valid: false,
            e1: true,
            password: '',
            passwordRules: [
              (v) => !!v || 'Password is required!!!',
            ],
            email: '',
            emailRules: [
              (v) => !!v || 'E-mail is required!!!',
            ],
          }
        },
        methods: {
          async submit () {
            this.$store.state.loadMenu=true;
            if (this.$refs.form.validate()) {
               this.$store.dispatch({
                                    type: 'doLogin',
                                    username: this.account.username,
                                    password: this.account.password,
                                  }).then((data) => {
                                            //  alert(data)
                                            this.$store.state.loadMenu = false;
                                            if(data==false){
                                              // alert("data==false")
                                              this.$router.push("/login-account").catch(()=>{});
                                            }
                                            // else{
                                            //   alert("data==else")
                                            // }
                                  });

            }
          },
          clear () {
            this.$refs.form.reset()
          }
        },
    computed: {
       buttonsmall() {
     if(this.$vuetify.breakpoint.name === "xs"){
       return true;
     }
      },
       widthcard() {
      switch (this.$vuetify.breakpoint.name) {
       case "xs":
          return "width:90%";
        case "sm":
            return "width:65%";
        case "md":
           return "width:45%";
        case "lg":
           return "width:35%";
        case "xl":
            return "width:25%";
      }
    },
    },


    }
</script>

<style scoped lang="scss">
.buttons {
  display: flex;
  justify-content: flex-end;
}
.v-progress-circular {
  margin: 1rem;
}
.row {
  padding-top: 40px;
  padding-bottom: 40px;
  align-items: center;
  justify-content: center;
}
.layout {
  margin-top: 20px;
  justify-content: center;
}
.fill-height {
  height: 100%;
}
.v-text-field.v-text-field--enclosed {
  margin-right: 25px;
  margin-left: 25px;
}
.linkunderline {
  margin-left: 30px;
  margin-top: -10px;
  margin-bottom: 10px;
}
.linkunderline:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media (min-width: 960px) {
  .login-container {
    margin-top: 2rem
  }
}
</style>
