export const lg_create_meetroom = {
  create_meetroom_th: {
    title: "ยินดีต้อนรับเข้าสู่ระบบ Dmeets",
    create_room_now: "สร้างห้องตอนนี้",
    create_room_schedule: "สร้างห้องล่วงหน้า",
    meet_topic: "หัวข้อการประชุม",
    meet_date_start: "วันที่เริ่ม",
    meet_time_start: "เวลาที่เริ่ม",
    meet_password: "รหัสผ่าน (ใส่หรือไม่ใส่ก็ได้)",
    meet_create: "สร้างห้อง",
    meet_option: "ตัวเลือกการประชุม",
    meet_option_lobby: "ห้องรองรับ",
    meet_sub__option_lobby: " เฉพาะผู้ใช้ที่ได้รับอนุญาตจากโฮสต์เท่านั้นจึงจะสามารถเข้าร่วมการประชุมได้",
    meet_option_verify_identify: "ยืนยันตัวตน",
    meet_option_share_screen: "อนุญาตให้ผู้เข้าร่วมแชร์หน้าจอ",
    titlemeeterror:"ผิดพลาด",
    contentmeeterror:"สิ้นสุดการประชุม",
    contentmeetiderror:"Meeting ID ไม่ถูกต้อง",
    enter_meeting_details:"กรุณากรอกรายละเอียดการประชุม",
    passcode:"รหัสผ่าน",
    passcode_subtitle:"เฉพาะผู้ใช้ที่มีรหัสผ่านเท่านั้นจึงจะสามารถเข้าร่วมการประชุมได้",
    requireauthenticationtojoin:"ต้องมีการยืนยันตัวตนเพื่อเข้าร่วม",
    OTPverification:"ยืนยันด้วย OTP",
    LogintoDmeets:"เข้าสู่ระบบ Dmeets",
    checkrequireauthenticationtojoin:"ตรวจสอบ ต้องมีการยืนยันตัวตนเพื่อเข้าร่วม",
    checkpasscode:"ตรวจสอบ รหัสผ่าน",
    checkmeet_topic: "ตรวจสอบ หัวข้อการประชุม",
  },
  create_meetroom_en: {
    title: "Welcome to Dmeets",
    create_room_now: "Create a room now",
    create_room_schedule: "Create a schedule room",
    meet_topic: "Meeting Topic",
    meet_date_start: "Start Date",
    meet_time_start: "Start Time",
    meet_password: "Password (optional)",
    meet_create: "Create a room",
    meet_option: "Meeting Options",
    meet_option_lobby: "Waiting Room",
    meet_sub__option_lobby: "Only users admitted by the host can join the meeting",
    meet_option_verify_identify: "Verify user's identity",
    meet_option_share_screen: "Allow share screen",
    titlemeeterror:"Error",
    contentmeeterror:"The meeting was finished",
    contentmeetiderror:"Meeting ID Incorrect",
    enter_meeting_details:"Please enter meeting details",
    passcode:"Passcode",
    passcode_subtitle:"Only users who have to passcode can join the meeting.",
    requireauthenticationtojoin:"Require authentication to join",
    OTPverification:"OTP verification",
    LogintoDmeets:"Login to Dmeets",
    checkrequireauthenticationtojoin:"Check Require authentication to join",
    checkpasscode:"Check Passcode",
    checkmeet_topic: "Check Meeting Topic",
  },
};
